$enable-rounded: true;
$enable-shadows: false;
$gray-800: #343a40;
$dark: $gray-800;
$cyan: #17a2b8;
$info: $cyan;
$gray-100: #f8f9fa;
$light: $gray-100;
$body-bg: $light;
$enable-gradients: false;
